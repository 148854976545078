@import (reference) '../../constants.less';

last-week-rescue {
  display: block;

  .weekday-container {
    display: inline-block;
    vertical-align: top;
    color: @white;
    background-color: @ph-secondary-grey;
    font-size: 1rem;
    height: 21px;
    width: 21px;
    text-align: center;
    border-radius: 25px;
    margin: 0 1px;

    &.has-usage {
      background-color: @ph-primary-purple;
    }

    &.has-many-usages {
      background-color: @ph-red;
    }

    span {
      line-height: 21px;
      vertical-align: middle;
    }
  }

  .total-rescue-count {
    margin-left: 5px;
    color: @ph-primary-purple;

    &.has-many-usages {
      color: @ph-red;
    }
  }
}
