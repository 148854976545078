@import (reference) '../../constants.less';

filter-options {
  display: block;
  margin-left: -5px;

  @media @condensed-desktop {
    margin-left: 0px;
  }
}

.filter-option,
.filter-option:focus {
  cursor: pointer;
  font-size: 1.5rem;
  color: #444444;

  margin: 15px 0 15px 5px;
  padding: 0 0 0 10px;

  border: solid transparent;
  border-width: 0px 0px 0px 5px;

  &:hover {
    color: @black;
  }

  &.selected {
    border-color: @ph-primary;
  }

  @media @condensed-desktop {
    display: inline-block;

    margin-left: 0px;
    padding: 5px 8px 0;

    &.selected {
      margin-left: 5px;
      border-left-width: 0px;
      border-bottom-width: 5px;
    }
  }
}
