@import (reference) '../../constants.less';

.profile-settings {
  #profile-settings-info-container {
    position: relative;

    #profile-settings-settings,
    #profile-settings-profile-picture-container {
      display: inline-block;
    }

    #profile-settings-profile-picture {
      width: 100px;
      height: 100px;
      font-size: 3.4em;
      text-align: center;
      color: white;
      background-color: @ph-primary-purple;
      border: 5px solid @ph-primary-purple;
      border-radius: 100%;
    }

    #profile-settings-settings {
      position: absolute;
      margin-left: 50px;

      .form-group {

        &.profile-settings-group-and-role-container {
          margin-bottom: 20px;
        }

        #profile-settings-group-and-role {
          margin-top: 10px;
          padding-left: 10px;
          font-size: 1.1em;
        }
      }

      .update-profile {
        margin: 20px 0;
        width: 550px;

        .profile-settings-res-message {
          margin-left: 10px;
          display: inline;

          &.success {
            color: @brand-success;
          }

          &.error {
            color: @state-danger-text;
          }
        }
      }
    }
  }

}
