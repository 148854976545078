.adherence-bar {
  margin-top: -0.9rem;
  margin-bottom: -0.9rem;
}

.adherence-bar_med-name {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
}

.horizontal-bar-container {
  width: 82%;
  height: 4px;
  overflow: hidden;
  background-color: #e2e2e2;
  .progress-bar {
    height: 100%;
    background-color: #01d3b2;
  }
}
