@import (reference) '../../constants.less';

.password-and-account-settings {
  .form-group {
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

  #password-and-account-settings-new-password {
    margin-bottom: 10px;
  }

  .password-and-account-settings-password-strength-container {
    .password-and-account-settings-password-strength {
      width: 74px;
      display: inline-block;
      border-style: solid;
      border-radius: 10px;
      border-width: 1px;
      height: 10px;
      background-color: @gainsboro;
      border-color: @gainsboro;
      color: @gainsboro;
    }
    .noPassword {
      background-color: @gainsboro;
      border-color: @gainsboro;
      color: @gainsboro;
    }
    .weakPassword {
      background-color: @state-danger-text;
      border-color: @state-danger-text;
      color: @state-danger-text;
    }
    .so-soPassword {
      background-color: @ph-orange;
      border-color: @ph-orange;
      color: @ph-orange;
    }
    .goodPassword {
      background-color: @ph-primary-yellow;
      border-color: @ph-primary-yellow;
      color: @ph-primary-yellow;
    }
    .strongPassword {
      background-color: @ph-cyan-mark;
      border-color: @ph-cyan-mark;
      color: @ph-cyan-mark;
    }
    .veryStrongPassword {
      background-color: @brand-success;
      border-color: @brand-success;
      color: @brand-success;
    }
    span {
      display: block;
      background-color: @white !important;
      &.noPassword {
        padding: 10px;
      }
    }
  }
  .password-and-account-settings-save-new-password {
    text-align: center;
    margin-top: 2rem;

    .password-and-account-settings-res-message {
      &.success {
        color: @brand-success;
      }
      &.error {
        color: @state-danger-text;
      }
    }
  }

  @media only screen and (max-width: 1086px) {
    .password-and-account-settings-save-new-password {
      margin-left: 0px;
    }
    .password-and-account-settings-password-strength-container {
      margin-left: 0px !important;
    }
  }
}
