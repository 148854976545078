@import (reference) '../../constants.less';

.patients-list {
    text-align: left;

    .patient-list-container{
        position: relative;
        display: inline-block;
        vertical-align: top;
        color: @black;
        height: 100%;
        width: 86%;

        loading {
            height: 100%;
            color: @ph-light-grey;

            .loading-spinner {
                max-width: 120px;
            }
        }
    }

    @media @condensed-desktop{
        .patient-list-container {
            display: block;
            width: 100%;
            height: 84%;
            .patient-list{
                padding-bottom: 50px;
            }
        }
    }

    //syncronize the widths of the patient headers and the row widths here
    .patient-list-header,
    patient-table-row,
    .default-text {
        padding: 1rem 20px;
        min-height: 45px;
        background-color: @white;
        border: 0px solid @white-smoke;
        border-bottom-width: 2px;

        .sort-header,
        .patient-datum {
            display: inline-block;
            vertical-align: top;
            height: 100%;
        }
    }

    // these paddings are getting overridden above from what's
    // defined in patient-table/styles.less
    // until we carve these down more to handle scoping, include here
    .patient-list-header {
        padding-top: 15px;
        padding-bottom: 5px;
    }

    //Column widths calculated here

    //TODO: use less functions to simplify this block
    @rescue-baseline-width: 19%;
    .patient-list-header,
    patient-table-row {
        //default widths
        @rescue-use-width: 23%;
        @default-column-width: ((100% - @rescue-use-width - @rescue-baseline-width) / 4);
        @sort-header-margin: 1%;

        .sort-header{
            width: @default-column-width - @sort-header-margin;
            margin-right: @sort-header-margin;

            &.rescue-use{
                width: @rescue-use-width - @sort-header-margin;
            }
            &.rescue-baseline {
                width: @rescue-baseline-width - @sort-header-margin;
            }
            span{
                font-family: @ph-main-font-bold;
            }
            .normal-title {
                display: initial;
            }
            .condensed-title {
                display: none;
            }
        }
        .patient-datum{
            width: @default-column-width;
            &.rescue-use{
                width: @rescue-use-width;
            }
        }

        @media @condensed-desktop {
            @extra-slack: 4%;
            @asthma-control-width: @default-column-width - @extra-slack;
            @new-rescue-use-width: @rescue-use-width + @extra-slack;
            @default-column-width: ((100% - @new-rescue-use-width - @new-rescue-baseline-width) / 4);
            @new-rescue-baseline-width: @rescue-baseline-width + @extra-slack;

            .sort-header{
                width: @default-column-width - @sort-header-margin;
                margin-right: @sort-header-margin;

                .condensed-title {
                    display: initial;
                }

                &.control-score,
                &.rescue-baseline {
                    .normal-title {
                        display: none;
                    }
                }

                &.rescue-use {
                    width: @new-rescue-use-width - @sort-header-margin;
                }
                &.rescue-baseline {
                    width: @new-rescue-baseline-width - @sort-header-margin;
                }
                &.control-score{
                    width: @asthma-control-width - @sort-header-margin;
                }
            }
            .patient-datum{
                width: @default-column-width;
                &.rescue-use {
                    width: @new-rescue-use-width;
                }
                &.control-score{
                    width: @asthma-control-width;
                }
            }
        }

    }

    patient-table-row {
        .control-score {
            text-transform: capitalize;
            color: #BBBBBB;

            &.poor{
                color: @ph-red;
            }
            &.good{
                color: @ph-green;
            }
            &.fair{
                color: @ph-orange;
            }
        }

        .rescue-baseline {
            width: @rescue-baseline-width;
            color: @matterhorn;
        }
    }

    .default-text{
        padding-left: 0px;
        text-align: center;
    }
}
