@import (reference) '../../constants.less';

header#main-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.ph-navbar {
  box-shadow: @navbar-shadow;
  border: none;

  .navbar-header {
    width: 12.5%;
    min-width: 50px;
    float: left;

    .logo-condensed {
      display: none;
    }
  }

  .navbar-brand {
    padding: 10px 15px;
  }

  .nav-wrapper {
    width: auto;
    padding: 0 15px;
  }

  .vertical-spacer {
    float: left;
    height: @ph-nav-bar-height - 15;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: @ph-secondary-grey;
    margin: (15px/2) 6px;
  }

  .primary-nav {
    font-family: @ph-main-font-bold;
    font-size: 1.6rem;
    float: left;

    li {
      a {
        padding: 0 10px;

        &:focus {
          outline-offset: 0px;
        }

        span:not(.count-span) {
          display: inline-block;
          padding-top: 20px;
          padding-bottom: 14px;
          border-bottom: 6px solid transparent;

          &.condensed-title {
            display: none;
            // image has height of 30px
            padding-top: 15px;
            padding-bottom: 9px;
          }
        }
      }

      &.selected a span,
      a:hover span {
        border-bottom-color: @ph-primary;
      }
    }
  }

  .navbar-nav {
    margin: 0;
  }

  // a lot of these floats and such are really to keep them applied
  // on smaller screens, since bootstrap only has the floats
  // on screen widths >= 768px
  .navbar-left {
    float: left;
  }

  .navbar-right {
    float: right;
    margin-right: -15px;
  }

  .navbar-form {
    padding: 0 5px;
    margin-left: 0;
    margin-right: 0;
    border: none;
    box-shadow: none;
  }

  .offline-text {
    margin-left: 5px;
    margin-right: 5px;
  }

  .navbar-nav > li > .dropdown-menu {
    border-radius: 4px;
  }

  .secondary-nav > li {
    // tooltip is positioned based on the padding of the element,
    // not the margin
    // set top/bottom margins individually, since element dimensions vary
    padding: 0 5px;
    float: left;

    &:last-of-type {
      padding-right: 0;
    }
  }

  li.circular-link {
    a {
      background: @white;
      padding: 0;
      border-radius: 100%;
      border: 2px solid @black;
      text-align: center;

      &:hover,
      &:focus {
        color: @white;
        background-color: @black;
      }

      &:focus {
        outline: 0;
        border-color: @input-border-focus;
        box-shadow: @input-focus-shadow;
      }
    }

    &.selected a {
      color: @white;
      background-color: @black;
      border-color: @black;
    }
  }

  li.add-patient-button {
    margin: 12px 0;

    a {
      width: 36px;
      height: 36px;
      line-height: 2;

      img {
        width: 50%;
      }

      .hover-state {
        display: none;
      }
    }

    a:hover,
    a:focus {
      .hover-state {
        display: initial;
      }

      .default-state {
        display: none;
      }
    }
  }

  li.notifications-button {
    margin: 16px 0;

    a {
      padding: 2px;
      width: 28px;
      height: 28px;
      display: inline-block;
      font-size: 11px;
    }

    .notification-count {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
    }
  }

  .navbar-tab-link {
    .notification-count {
      position: absolute;
      top: 10px;
      right: -2px;
      z-index: 1;
    }
  }

  li.support-dropdown {
    // while we set margins on the top and bottom for the links
    // to make the tooltips appear where we want
    // for the dropdowns the tooltips are fine with padding,
    // and the menu appears too high if we use margins.
    // presumably this is matter of what's used as the reference element
    // to position the tooltip and menu, and for the dropdowns we integrate
    // tooltips slightly differently. sigh.
    padding-top: 16px;
    padding-bottom: 16px;
  }

  li.account-dropdown {
    padding-top: 12.5px;
    padding-bottom: 12.5px;

    .username {
      color: @black;
      font-size: 1.4rem;
      text-transform: none;
      margin: 0;
    }
  }

  .ph-dropdown button {
    padding: 0;
    font-size: 1em;
    transition: all 75ms ease 0s;
  }

  button#support-menu {
    height: 28px;
    width: 28px;

    &:hover {
      border-color: @black;
    }

    &[aria-expanded='true'] {
      background-color: @black;
      color: @white;
      border-color: @black;
    }
  }

  button#account-settings {
    font-family: @ph-main-font-bold;
    width: 35px;
    height: 35px;
    border: 2px solid @white;
    color: @white;
    background-color: @ph-primary-purple;

    &.selected,
    &:hover,
    &:focus {
      border-width: 3.5px;
      border-color: @input-border-focus;
    }
  }

  @media @condensed-desktop {
    .navbar-header {
      width: 50px;
      margin-right: 8px;

      .logo-condensed {
        display: block;
      }

      .logo-wide {
        display: none;
      }
    }

    .condensed-title {
      display: inline-block !important;
    }

    .normal-title {
      display: none !important;
    }
  }
}
