@import (reference) '../../constants.less';
@import (reference) '../../animations.less';

notification-item {
    display: block;
    height: 75px;
    min-height: 75px;

    background-color: @white;
    border-bottom: solid 2px @white-smoke;

    transition: height 300ms ease-in, border-bottom-width 300ms ease-in;

    &.shake {
        animation-duration: 600ms;
        animation-timing-function: ease-in-out;
        animation-name: shake;
    }

    &.remove {
        overflow: hidden;
        min-height: unset;
        height: 0;
        border-bottom-width: 0;
        transform-origin: top left;
        animation-duration: 300ms;
        animation-timing-function: ease-in;
        animation-name: slideOut;
    }
}

.notification-item {
    display: table-row;
    height: 75px;
    min-height: 75px;
    background-color: white;
    font-size: 1.1em;

    div {
        vertical-align: middle;
    }

    .notification-image-container {
        display: table-cell;
        padding-left: 20px;
        padding-right: 20px;
        .notification-image-margin {
            margin-right: 20px;
            .notification-image {
                width: 35px;
                height: 35px;
            }
            .arrivedToday {
                width: 39px;
                height: 39px;
                border-style: solid;
                border-color: lightblue;
                border-width: 3px;
                border-radius: 100%;
                margin-left: -4px;
            }
        }
    }
    .notification-message-container {
        display: table-cell;
        width: 100%;
        .notification-message {
            display: inline-block;
            .notification-user-name {
                font-family: @ph-main-font-bold;
                text-transform: capitalize;
            }
            .notification-detail {
                font-weight: lighter;
            }
        }
        .notification-time {
            display: block;
            font-size: 15px;
            color: #979797;
        }
    }
    .notification-action-container {
        display: table-cell;
        padding-right: 20px;
        .notification-action-margin {
            margin-left: 20px;
            button {
                &.notification-resolved {
                    border-color: #8D8D8D;
                    color: #8D8D8D;

                    &:hover {
                        color: #ffffff;
                        background-color: #8D8D8D;
                    }
                }
            }
        }
    }
}