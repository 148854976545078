@import (reference) '../../constants.less';

.ph-dropdown {
  .dropdown-menu {
    padding: 10px;
    width: 180px;

    .dropdown-header {
      text-transform: uppercase;
      font-size: 0.8em;
      color: @nobel;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0;
    }

    li {
      padding: 0;
      position: relative;

      // apply negative margin to counteract the ul's padding and reapply
      // the padding on the link element t0 allow a wide focus ring
      &[role='presentation'] {
        margin: 0 -10px;
      }
    }

    .fa {
      display: none;
      color: @ph-primary;
      position: absolute;
      left: 3px;
    }

    [role='menuitem'] {
      padding: 2px 10px 2px 18px;
      white-space: normal;

      &:hover,
      &:focus {
        background: none;

        .fa {
          display: inline-block;
        }
      }
    }
  }
}
