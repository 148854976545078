.no-data-placeholder {
  width: 100%;
  height: 200px;
  text-align: center;
  color: #DDD;

  @media print {
    color: #DDD !important;
  }

  img {
    display: block;
    margin: 1rem auto;
    height: 50px;
    width: auto;
  }

  .no-data-title {
    text-transform: uppercase;
  }
}
