@import (reference) '../../constants.less';

on-off-label {
  .on-status {
    color: @dark-blue;
  }
  .off-status {
    color: @ph-grey-medium;
  }
}
