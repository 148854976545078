@import (reference) '../../constants.less';

asthma-control-score {
  .condensed-datum {
    display: none;
  }

  @media @condensed-desktop {
    .normal-datum {
      display: none;
    }
    .condensed-datum {
      display: block;

      img {
        width: 26px;
      }
    }
  }
}
