#select-care-team-member {
  font-size: 1.5rem;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 30px;
}

// padding here to avoid any just so fits in other filter options
.care-team-options {
  padding-right: 10px;
  padding-bottom: 20px;
}
