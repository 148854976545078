@import (reference) '../../constants.less';

.ph-button {
  font-family: @ph-main-font;
  border-radius: 25px;
  font-size: 16px;
  padding: 12px 15px;

  border: 1px solid;
  width: 100%;
  display: inline-block;

  &--primary {
    color: @myrtle;
    background-color: @ph-green;
    border-color: @ph-green;

    &:hover,
    &:focus {
      color: @white;
      background-color: @ph-dark-green;
      border-color: @ph-dark-green;
    }
  }

  &--secondary {
    background-color: @white;
    color: @night-rider;

    &:hover,
    &:focus {
      color: @white;
      background-color: @night-rider;
      border-color: @night-rider;
    }
  }

  &--danger {
    background-color: @ph-red;
    border-color: @ph-red;

    &:hover,
    &:focus {
      background-color: @ph-error-text;
      border-color: @ph-error-text;
    }
  }

  &--link {
    color: @dark-blue;
    background: none;
    padding: 0 !important;
    width: auto;
    border: none;

    &:hover,
    &:focus {
      color: @link-hover-color;
      text-decoration: underline;
      box-shadow: none !important;
    }
  }

  &--large {
    padding-top: 12px;
    padding-bottom: 12px;
    max-width: 34.4rem; // ??!!
  }

  &--small {
    width: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    // max-width: 11.8rem; // ??!!
  }

  &--xsmall {
    width: auto;
    font-size: 14px;
    padding-top: 1px;
    padding-bottom: 1px;
    // max-width: 11.8rem; // ??!!
  }

  &:focus {
    outline: none;
    box-shadow: @input-focus-shadow;
  }

  &.block {
    display: block;
    max-width: none;
  }

  &:disabled,
  &[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.input-group-btn {
  width: auto;
  display: inline-block;

  .ph-button {
    border-width: 2px;
  }

  &:last-child > .ph-button {
    position: relative;
    z-index: 2;
    margin-left: -2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: @input-height;
    padding: 6px 14px 6px 10px;
  }
}
