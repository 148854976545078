@media print {
  // redeclare these angularjs selectors here, as the standard version
  // includes a selector that breaks when Edge prints, resulting in
  // elements which should be hidden appearing in the printed output
  // https://gitmemory.com/issue/angular/angular.js/15026/506466006
  [ng-cloak],
  [data-ng-cloak],
  [x-ng-cloak],
  .ng-cloak,
  .x-ng-cloak,
  .ng-hide:not(.ng-hide-animate) {
    display: none !important;
  }

  // to stop it from showing href src links, hide the body and only show the modal
  a[href]:after {
    content: none !important;
  }

  html {
    overflow: visible !important;
  }

  body {
    overflow: visible !important;

    .print-hidden {
      visibility: hidden;
    }

    .print-none {
      display: none;
    }

    .print-visible {
      display: initial !important;

      &.ng-hide {
        display: initial !important;
      }
    }

    .modal {
      position: static;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      padding: 0;
      min-height: 550px;
      visibility: visible;
      /**Remove scrollbar for printing.**/
      overflow: visible !important;
      background: white;
    }

    .modal-wrapper {
      .modal-dialog {
        visibility: visible !important;
        /**Remove scrollbar for printing.**/
        overflow: visible !important;
        padding-top: 0px;
        width: 100%;
      }

      .modal-content {
        border-top: none;
      }
    }

    // here is the problem
    li {
      page-break-after: avoid-region;
    }

    .patient-detail {
      visibility: visible;
      overflow-y: visible;
      padding: 0 !important;
      margin: 0 !important;

      .header-container {
        border: none;
        padding: 0 !important;
        margin: 0 !important;
        button {
          display: none;
        }
      }

      patient-header {
        border: none;
        height: auto;
      }

      .ph-tabs-wrapper {
        display: none;
      }

      .tab-content {
        position: static;
        padding-top: 0;
        overflow: visible;
      }

      button {
        display: none;
      }

      .patient-graph-container .patient-chart-container {
        margin-top: 0;
      }

      .info-block-wrapper.collapsible .info-content.collapsed {
        height: auto;
        opacity: 1;
      }
    }

    .print-avoid-break {
      page-break-inside: avoid;
      page-break-before: unset;
      page-break-after: unset;
      break-before: unset;
      break-after: unset;
      break-inside: avoid;
    }
  }

  html.smart-app body main {
    top: 0;
    margin-top: 0;
    padding-top: 0;

    .page-wrapper {
      padding: 0 !important;
      overflow: visible !important;
    }

    patient-detail-page-wrapper {
      padding: 0;
    }

    .container {
      width: 100%;
    }

    .main-content {
      border: none;
      box-shadow: none;
    }
  }
}
