@import (reference) '../../constants.less';

.notification-settings {
  .notification-setting {
    margin-top: 20px;
    margin-left: 40px;
    font-size: 1.1em;

    on-off-label {
      margin-left: 15px;
    }
  }

  .notification-settings-group {
    margin-bottom: 50px;

    .notification-settings-group-title {
      display: table;
      color: @dark-blue;
      font-size: 1.2em;

      .notification-settings-group-image {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 7px;
      }

      .notification-settings-group-text {
        display: table-cell;
      }
    }
  }
}
