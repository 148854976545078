@import (reference) '../../constants.less';

.sort-header {
  border-right: 2px solid @white-smoke;
  padding-top: 5px;
  font-size: 1em;
  cursor: pointer;
  position: relative;

  .sort-container {
    position: absolute;
    right: 5px;
    top: 0;
  }

  &.current-sort .row-title-text {
    padding-right: 20px;
  }

  .hover-tooltip {
    @tooltip-width: 140px;

    white-space: normal;
    font-family: @ph-main-font;
    width: @tooltip-width;
    top: 40px;
    right: -((@tooltip-width - 22) / 2);

    &:before {
      left: ((@tooltip-width - 20) / 2);
    }
    &:after {
      left: ((@tooltip-width - 20) / 2) + 1;
    }
  }

  .fa {
    color: @ph-primary;
  }

  &:hover {
    .normal-title,
    .condensed-title {
      text-decoration: underline;
    }
  }

  &.unsortable {
    cursor: default;

    &:hover {
      .normal-title,
      .condensed-title {
        text-decoration: none;
      }
    }
  }

  .sort-arrow {
    height: 0.8em;
    margin-bottom: 3px;

    &.arrow-down {
      transform: rotate(180deg);
    }
  }
}
