@import (reference) '../../../constants.less';

.search-input-container {
  position: relative;

  input[type='search'] {
    width: 360px;
    background-color: @white;
    border-color: @gainsboro;
    color: @black;
    border-width: 1px;
    height: 34px;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 30px;
    text-overflow: ellipsis;
    overflow: hidden;

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: @nobel;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }

    &:focus {
      // outline: none;
      border-color: @input-border-focus;
      box-shadow: @input-focus-shadow;
      // background-color: @white;

      &::placeholder {
        color: @black;
      }
    }
  }

  button {
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: @white;
    border-color: @white;
    line-height: 1;
    // border: none;
    font-size: 13px;

    &:hover,
    &:focus {
      background-color: @whisper;
      border-color: @whisper;
      color: @black;
    }

    &:focus {
      border-color: @input-border-focus;
      box-shadow: @input-focus-shadow;
    }
  }

  @media @condensed-desktop {
    input[type='search'] {
      width: 100%;
    }
  }
}
