.notification-badge {
  position: absolute;
  border: solid thin red;
  background-color: red;
  text-align: center;
  top:0;
  right: 0;
  font-size: 11px;
  padding: .5px;
  min-height: 17px;
  max-height: 17px;
  min-width: 17px;
  color: white;
  z-index: 1;

  .number-of-notifications {
    margin: 0 2px;
  }
}

.less-than-eleven {
  border-radius: 100%;
}

.greater-than-eleven {
  border-radius: 25px;
  width: auto;
}
