@import (reference) '../../constants.less';

.ph-tooltip {
  .tooltip-arrow::before {
    // keep this pseudo element from interfering with the
    // trigger element
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    top: -11px;
    left: -10px;
    z-index: -1;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #e8e8e8;
  }

  .tooltip-inner {
    box-shadow: 1px 1px 5px 1px @ph-secondary-grey;
    padding: 8px;
    border-radius: 5px;
    hyphens: auto;
    font-size: 1.4rem;
  }
}
