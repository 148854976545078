@keyframes shake {
  0%,
  to {
    // eqiuivalent to translate3d(0,0,0)
    transform: translateZ(0);
  }
  14.25%,
  42.86%,
  71.43% {
    transform: translate3d(-10px, 0, 0);
  }
  28.57%,
  57.14%,
  85.71% {
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes slideOut {
  from {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(0, 0, 0);
  }
}
