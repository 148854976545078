.session-timeout {
    display: table;
    width: 100%;
    height: 100%;
    .session-timeout-container {
        display: table-cell;
        padding-top: 50px;
        text-align: center;
        img {
            width: 50px;
        }
        span {
            font-size: 1.3em;
            display: block;
            margin-top: 10px;
            color: #99cadf;
        }
    }
}