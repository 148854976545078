@import (reference) '../../constants.less';

// we need to take a closer look at these after refactoring the rest
// of the patients-lists and related UI
patient-table {
  @patient-list-header-height: 70px;

  display: block;
  height: 100%;

  .patient-list-header {
    padding-top: 15px;
    padding-bottom: 5px;
    color: @ph-primary;
    height: @patient-list-header-height;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 1;

    .bookmark-tooltip {
      top: -5px;
    }

    row-header:last-of-type .sort-header {
      border-right-style: none;
    }
  }

  .patient-list {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    padding-top: @patient-list-header-height;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .no-results-container {
      display: table;
      width: 100%;
      height: 100%;

      .no-results {
        display: table-cell;
        padding-top: 50px;
        text-align: center;
        img {
          &.no-patients {
            width: 60px;
          }
          width: 50px;
        }

        div {
          font-size: 1.3em;
          margin-top: 10px;
          color: #99cadf;
        }
      }
    }
  }

  .patient-names-hide-show-container {
    line-height: 1.5em;

    .eye-tooltip.hover-tooltip {
      top: 75%;
      left: 12px;
    }
  }

  .patient-names-hide-show {
    width: 24px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
    outline: none;
  }
}

patient-table-header {
  display: block;
}

patient-table-row {
  display: block;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: @white-smoke;
  }
}
