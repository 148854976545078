.settings {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-left: 25%;
  padding-right: 25%;
  background-color: white;

  h1 {
    font-size: 2.5em;
    margin-bottom: 30px;
  }
}
