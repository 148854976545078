@import (reference) '../../constants.less';

// use -2 for now to avoid colliding with existing styles
.circular-button-2 {
  color: @black;
  background-color: @white;
  border-radius: 100%;
  border: 2px solid @black;
  text-align: center;

  &:hover,
  &:focus {
    color: @white;
    border-color: @black;
    background-color: @black;
  }

  &:hover {
    border-color: @black;
  }

  &:focus {
    border-color: @input-border-focus;
    box-shadow: @input-focus-shadow;
  }
}