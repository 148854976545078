@import (reference) '../../constants.less';

// we have so much replication
.announcement-modal-wrapper {
  .modal-dialog {
    height: initial;
  }
}

.announcement-modal-container {
  close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .modal-body {
    display: block;
    padding: 3rem 6rem;
    box-sizing: border-box;
    text-align: center;

    h1 {
      font-size: 3rem;
      margin-bottom: 3rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  .announcement-text {
    font-size: 1.6rem;
    margin: 1 rem 0;
    text-align: left;
  }

  .announcement-preview {
    width: 100%;
    max-width: 100%;
    margin: 2rem 0;
    padding: 0 2rem;
  }

  .vertically-centered-container {
    height: 100%;
  }

  ph-button {
    display: block;
    margin: 2rem auto 1rem;

    // angular components...
    button.ph-button {
      font-family: @ph-main-font-bold;
      font-size: 1.5em;
    }
  }
}
