.toggle-button {
  vertical-align: middle;
  padding: 0;
  border: none;
  background: none;
  color: #333333;
}
.toggle-button .toggle {
  display: inline-block;
  margin: 0 1rem 0 0;
  width: 44px;
  height: 24px;
  border: solid 2px #333333;
  background-color: #f8f8f8;
  border-radius: 50px;
  cursor: pointer;
  vertical-align: middle;
}
.toggle-button .toggle .knob {
  display: inline-block;
  margin-top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #5c5c5c;
  transition-duration: 0.4s;
  transform: translate(-10px, 0);
}
.toggle-button:focus {
  outline: none;
}
.toggle-button:focus .toggle {
  box-shadow: 0 0 5px 2px rgba(32, 195, 243, 0.75);
}
.toggle-button[aria-pressed='true'] .toggle {
  background-color: #00799d;
}
.toggle-button[aria-pressed='true'] .knob {
  transform: translate(10px, 0);
  background-color: #f8f8f8;
}
.toggle-button[aria-disabled='true'] {
  opacity: 0.5;
}
.toggle-button[aria-disabled='true'] .toggle {
  cursor: not-allowed;
}
.toggle-button .toggle-text {
  vertical-align: middle;
}
