@import (reference) '../../constants.less';

.loading-spinner {
  max-width: 120px;
}

.loading-message {
  color: @black;
  font-size: 1.6rem;
}
