@import (reference) '../../constants.less';

.toggle-button {
  vertical-align: middle;
  padding: 0;
  border: none;
  background: none;
  color: @night-rider;

  .toggle {
    display: inline-block;
    margin: 0 1rem 0 0;
    width: 44px;
    height: 24px;
    border: solid 2px @night-rider;
    background-color: @white-smoke;
    border-radius: 50px;
    cursor: pointer;
    vertical-align: middle;

    .knob {
      display: inline-block;
      margin-top: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: @zambezi;

      transition-duration: 0.4s;
      transform: translate(-10px, 0);
    }
  }

  &:focus {
    outline: none;

    .toggle {
      box-shadow: @input-focus-shadow;
    }
  }

  &[aria-pressed='true'] {
    .toggle {
      background-color: @dark-blue;
    }

    .knob {
      transform: translate(10px, 0);
      background-color: @white-smoke;
    }
  }

  &[aria-disabled='true'] {
    opacity: 0.5;

    .toggle {
      cursor: not-allowed;
    }
  }

  .toggle-text {
    vertical-align: middle;
  }
}
