@import (reference) '../../constants.less';

favorite-patient-toggle {
  cursor: pointer;

  .favorite-icon {
    position: absolute;
    top: -2px;
    left: 0px;
    width: 14px;
    height: 20px;
    cursor: pointer;
    color: @ph-primary;

    span {
      display: none;
      &.favorited {
        display: block;
      }
    }
    &.hovered {
      color: @ph-secondary;
      span {
        display: block;
      }
    }
  }
}
