/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * propellerhealth.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2016
 */

@font-face {
  font-family: 'Circular-Pro-Book-S';
  src: url('../assets/fonts/lineto-circular-pro-book.eot');
  src: url('../assets/fonts/lineto-circular-pro-book.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/lineto-circular-pro-book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Pro-Medium';
  src: url('../assets/fonts/lineto-circular-pro-medium.eot');
  src: url('../assets/fonts/lineto-circular-pro-medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/lineto-circular-pro-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Pro-Bold-S';
  src: url('../assets/fonts/lineto-circular-pro-bold.eot');
  src: url('../assets/fonts/lineto-circular-pro-bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/lineto-circular-pro-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
