.notification-count {
  background-color: red;
  color: white;
  text-align: center;
  font-size: 11px;
  min-width: 17px;
  height: 17px;
  line-height: 17px;
  border-radius: 100%;
  padding: 0 2px;

  &.greater-than-eleven {
    border-radius: 25px;
  }
}
