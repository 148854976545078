@import (reference) './constants.less';

.inputWidth(@width) {
  .input--width-@{width},
  .ph-input-group input.input--width-@{width} {
    width: @width + 3ch;
  }
}

label {
  font-family: @ph-main-font-medium;
  font-weight: 500;

  // if help block is in the label, don't add additional margin
  .help-block {
    margin: 0;
  }
}

// some of these seem to only be applied for the search input in the nav bar
input,
select{
  width: 100%;
  border: 1px solid #CEC9C9;
  font-size: 1.25em;
  font-weight: 300;
  color: @ph-input-color;
  border-radius: 0.25em;
  padding-left: 20px;
  background-color: @light-grey;
  font-family: @ph-main-font-medium;

  &:focus{
      outline: none;
      border-color: @ph-primary;
      box-shadow: 0 0 0.2em @ph-primary;
      background-color: @white;
  }
}

input.form-control,
select.form-control {
  height: @input-height;
  width: 100%;
  max-width: 400px;
  color: @black;
  background-color: @white;
  border: 2px solid;
  padding: 6px 7px;
  font-size: 1.75rem;

  &:focus {
    box-shadow: @input-focus-shadow;
  }

  &[readonly],
  &[disabled],
  &[aria-disabled='true'] {
    box-shadow: none;
    background-color: @background-grey;
    color: @zambezi;
    border-color: @ph-secondary-grey;
  }
}

.ph-select {
  display: block;
  width: 100%;
  height: @input-height;
  font-size: 1.75rem;
  color: @black;
  padding-left: 7px;
  padding-right: 6px;

  border-radius: 4px;
  border-width: 2px;

  background-position: right center !important;
  background-color: @white;
  background-image: url('../assets/images/select_arrow.svg');
  background-repeat: no-repeat;
  background-size: 32px 32px;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    box-shadow: @input-focus-shadow;
  }
}

input[type='radio'],
input[type='checkbox'] {
  width: initial;
  max-width: initial;
  height: initial;
  border: none;
  color: initial;
  background: initial;
  border-radius: initial;
  text-align: initial;
}

.input-group-btn button {
  height: @input-height;
}

.fields-inline {
  .form-group {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }
}

.inputWidth(2);
.inputWidth(3);
.inputWidth(4);
.inputWidth(5);
.inputWidth(6);
.inputWidth(7);
.inputWidth(8);
.inputWidth(9);
.inputWidth(10);

::placeholder {
  font-style: italic;
}
