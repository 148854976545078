@import (reference) '../../constants.less';

last-contact {
  .vertically-centered-element {
    > div,
    display-date {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .circular-container {
    margin-right: 5px;
    margin-top: -4px;
    .circular-element {
      background-color: @ph-primary-purple;
      border-color: @ph-primary-purple;
      color: @white;
      font-family: @ph-main-font-bold;
    }
  }
}
