@import (less) '../assets/fontello-icons/css/fontello.css';
@import 'bootstrap/less/bootstrap.less';
@import 'angular/angular-csp.css';
@import './constants.less';
@import './fonts.less';
@import './animations.less';

//BOOTSTRAP Overrides
// .modal {
//   height: 100%;
//   .modal-dialog {
//     height: 100%;
//   }
// }
@media @condensed-desktop {
  .modal .modal-dialog {
    width: auto !important;
    margin: 0 10px !important;
  }
}

div:focus,
span:focus {
  outline: none;
}

/**
 * App-wide Styles
 */

body {
  margin: 0px;
  background-color: @white-smoke;
  //min-width: 667px;
}

html,
body {
  font-family: @ph-main-font;
  color: @text-color;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

html.reset-layout,
html.reset-layout body {
  height: auto;
  overflow: auto;

  min-height: 100vh;

  .app-body {
    position: initial;
  }

  .page-wrapper {
    height: initial;
    overflow: auto;
  }

  header.nav-bar,
  navbar,
  nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

html.ph-enrollment {
  .app-body {
    margin-top: 80px;
  }
}

html.smart-app {
  main {
    margin-top: 60px;
  }
}

button,
a,
.ph.has-transition {
  transition: all 0.3s ease 0s;
}

.ph {
  &.no-transition {
    transition: none !important;
  }

  &.vertically-centered-container {
    display: table;
    height: 100%;
    width: 100%;
    .vertically-centered-element {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

button {
  display: block;
  font-size: 1em;
  border: none;
  border-radius: 0.2em;
  color: white;
  text-align: center;
  padding: 14px 25px;
  font-family: @ph-main-font-bold;
}

b,
strong {
  font-family: @ph-main-font-bold;
  // color: @black;
  font-weight: normal;
}

a {
  color: @dark-blue;

  &:hover,
  &:focus {
    color: @link-hover-color;
  }
}

h1,
h2,
h3 {
  font-family: @ph-main-font-bold;
}

.app-version {
  text-align: right;
  font-size: 1.2rem;
  font-style: italic;
  color: @nobel;
}

.error {
  color: @ph-error-text;
}

.faded {
  color: #bbbbbb;
}

.loading-container {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 3em;

  .vertically-centered {
    font-size: 2em;
  }
}

.page-wrapper {
  height: 100%;
  padding-top: 20px;
}

.main-content {
  background-color: #ffffff;
  border: solid 1px #cccccc;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.clear-button {
  padding: 6px 10px;
  color: @ph-primary;
  background-color: white;
  border: 2px solid @ph-primary;
  border-radius: 5px;

  transition: all 0.2s linear;

  &:hover,
  &:focus {
    color: white;
    background-color: @ph-primary;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
  }

  &:active {
    // box-shadow: inset 1px 2px 2px rgba(0,0,0,0.3),
    //             inset -2px -2px 1px rgba(255,255,255,0.5);
    box-shadow: none;
    border-color: darken(@ph-primary, 20);
    background-color: darken(@ph-primary, 20);
  }
}

.circular-container {
  height: 27px;
  width: 27px;

  .circular-element {
    border-style: solid;
    border-width: 2px;
    border-radius: 100%;
    text-align: center;
    font-size: 12px;
  }
}

a {
  vertical-align: bottom;
  &:hover {
    cursor: pointer;
    text-transform: none;
    color: @ph-primary;
  }
}

.hover-tooltip {
  @tooltip-width: 150px;
  position: absolute;
  z-index: 2;
  background-color: white;
  color: black;
  box-shadow: 1px 1px 5px 1px @ph-secondary-grey;
  padding: 8px;
  border-radius: 5px;
  width: @tooltip-width;
  text-align: center;
  hyphens: auto;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #e8e8e8;
    left: ((@tooltip-width - 20) / 2);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
    left: ((@tooltip-width - 20) / 2) + 1;
  }

  &.right-aligned {
    &:before {
      left: -20px;
      top: 7px;
      transform: rotate(270deg);
    }
    &:after {
      left: -17px;
      top: 8px;
      transform: rotate(270deg);
    }
  }
}

.no-margin {
  margin: 0;
}

.modal-content {
  border: none;
  border-radius: 5px;
  border-top: solid 12px @ph-primary;
}

.modal-wrapper {
  .modal-dialog {
    height: 100%;
    padding: 30px 0;
    margin: 0 auto;
  }

  .modal-content {
    height: 100%;
  }
}

.modal-wrapper-edit-contact {
  .modal-dialog {
    max-height: 807px;
  }
}

.modal-wrapper-report-requested-modal {
  .modal-dialog {
    height: 35%;
    position: absolute;
    left: 25%;
    top: 25%;
  }
}

.modal-buttons-container {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 1.6em;
  color: #bdbcbc;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

button.as-link {
  font-family: @ph-main-font;
  color: @dark-blue;
  background: none;
  padding: 0;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: underline;
  }
}

.modal-footer {
  border-top: none;
  padding: 10px 20px;
  .modal-footer-cancel,
  .modal-footer-save {
    text-align: center;
    padding: 8px 10px;
    width: 125px;
    display: inline-block;
    vertical-align: middle;

    &:hover,
    &:focus {
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
  }
  .modal-footer-cancel {
    background-color: #ffffff;
    color: @ph-grey-medium;
    border-color: @ph-grey-medium;
    border-radius: 5px;
    border: 1px solid;
    border-width: 2px;
    margin-right: 10px;
    &:hover,
    &:focus {
      color: white;
      background-color: @ph-grey-medium;
      border-color: @ph-grey-medium;
    }
  }
  .modal-footer-save {
    background-color: #ffffff;
    color: @ph-primary;
    border-radius: 5px;
    border: 1px solid;
    border-color: @ph-primary;
    border-width: 2px;
    &:hover,
    &:focus {
      color: #ffffff;
      background-color: @ph-primary;
      border-color: @ph-primary;
    }
  }
}

.row-title-text {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: padding-right 100ms;
  color: @ph-primary;

  > span {
    white-space: nowrap;
  }
}

.subtitle {
  font-weight: normal;
  font-size: 0.8em;
  color: #3b88a2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .fa {
    color: #3b88a2;
  }
}

.nowrap {
  white-space: nowrap;
}

.black-text {
  color: black;
}

.grey-text {
  color: @ph-light-grey;
}

.dk-grey-text {
  color: @zambezi;
}

.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  // display: block;
  white-space: nowrap;
}

.control-score {
  text-transform: capitalize;
  color: #bbbbbb;
  &.poor {
    color: @ph-red;
  }
  &.good {
    color: @ph-green;
  }
  &.fair {
    color: @ph-orange;
  }
}

.copd-score {
  color: @black;

  &.normal,
  &.low {
    color: @ph-green;
  }

  &.medium {
    color: @ph-orange;
  }

  &.high,
  &.very-high {
    color: @ph-red;
  }
}

.bluetooth {
  color: #bbbbbb;

  &.bluetooth-on {
    color: @ph-green;
  }

  &.bluetooth-off {
    color: @ph-red;
  }
}

.copd-rescue-baseline {
  color: @ph-cyan-mark;
}

.section-content {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.tooltip-container {
  position: relative;
  display: inline-block;

  .fa {
    cursor: pointer;
    font-size: 1em;
  }

  .tooltip-message {
    @tooltip-width: 300px;
    width: @tooltip-width;
    text-transform: none;
    font-size: 1rem !important;
    top: 25px !important;
    margin-left: -140px;

    &:before {
      left: ((@tooltip-width - 20) / 2);
    }

    &:after {
      left: ((@tooltip-width - 20) / 2) + 1;
    }
  }
}

.chart-tooltip {
  position: absolute;
  z-index: 10;
  background: rgba(74, 74, 74, 0.98);
  color: @white;
  padding: 0.3rem 0.7rem;
  white-space: nowrap;
  border-radius: 2px;
  font-size: 1.2rem;
  line-height: initial;

  .tooltip-marker {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .tooltip-label {
    color: #aaa;
  }
}

.filter-sidebar {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;

  width: 14%;
  color: @black;
  padding: 0 6px;

  h4 {
    font-size: 1.1em;
    text-transform: uppercase;
  }

  @media @condensed-desktop {
    display: block;
    width: 100%;

    .patient-filters {
      height: 85px;
      padding-left: 12px;

      .filtered-list-title {
        margin-left: 5px;
      }
    }
  }
}

.list-style-none {
  list-style: none;
  padding: 0;
}

// this doesn't apply well in angular because of
// how it annotates the dom to apply the scope
ul.hide-empty:empty,
.empty-state {
  display: none;
}

ul.hide-empty:empty + .empty-state {
  display: block;
}

ul.hide-empty:empty ~ .non-empty-state {
  display: none;
}

@import './settings.less';
@import './forms.less';
@import './print.less';
