@import (reference) '../../constants.less';

img.no-smil {
  display: none;
}

html.no-smil {
  img.no-smil {
    display: initial;
  }
  img.smil {
    display: none;
  }
}

.loading-container {
  .loading-spinner {
    max-width: 190px;
    //height: 100%;
  }
  .loading-message {
    color: @black;
    font-size: 1.6rem;
  }
}