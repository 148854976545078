close-button {
  position: relative;
  height: 16px;
  width: 16px;
  float: right;
  text-align: center;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 2px;
  cursor: pointer;

  .hover-show {
    display: none;
  }

  img {
    height: 100%;
    position: absolute;
    top: 5px;
    left: 0px;
  }

  &:hover {
    img.default-show {
      display: none;
    }
    img.hover-show {
      display: inline;
    }
  }
}
